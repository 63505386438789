exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bg-catalog-category-data-id-js": () => import("./../../../src/pages/bg-catalog-{category.data_id}.js" /* webpackChunkName: "component---src-pages-bg-catalog-category-data-id-js" */),
  "component---src-pages-bg-product-details-product-data-id-js": () => import("./../../../src/pages/bg-product-details-{product.data_id}.js" /* webpackChunkName: "component---src-pages-bg-product-details-product-data-id-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-confirm-js": () => import("./../../../src/pages/checkout/confirm.js" /* webpackChunkName: "component---src-pages-checkout-confirm-js" */),
  "component---src-pages-checkout-done-js": () => import("./../../../src/pages/checkout/done.js" /* webpackChunkName: "component---src-pages-checkout-done-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */)
}

